<template>
  <div class="flex h-full items-center tabular-nums" :class="params.justifyStart ? 'justify-start' : 'justify-end'">
    {{ dollarValue }}
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { usdCryptoPrice } from '@/composeables/filters';

const props = defineProps({
  params: {
    type: Object,
    default: undefined
  }
});

const dollarValue = computed(() => usdCryptoPrice(props.params.value || '').toLocaleString());
</script>
